<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li>
					<li>내 예약 문의</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- e러닝 목록 -->
			<div class="mypage2-elearn">
				<div class="box-gray mb24">
					<strong>공간이용 문의내역</strong>
					<div class="flex align-items-center justify-content-between">
						<div class="fl">
							<span> 문의하신 내역입니다.</span>
						</div>
						<div class="fr">
							<router-link :to="'/space/0/inquiry/Input'" class="button-default is-rounded is-outlined">문의하기</router-link>
						</div>
					</div>
				</div>
				<!-- [DEV] 데이터 없는 경우 공통 -->
        <div class="no-results-found" v-if="this.inquiryList.length == 0">
          <p class="text">해당 내용이 없습니다.</p>
        </div>
        <div class="reserv-inquiry-list" v-else>
					<ul>
						<li v-for="(item, idx) in inquiryList" :key="idx" :class="{ 'is-active': visibleMore === idx }">
							<div class="textlist-item">
								<div class="textlist-item-header">
									<div class="textlist-item-header__top">
										<span class="textlist-item__date">{{ regDtTimeFormat(item.regDt) }}</span>
										<span class="textlist-item__tag is-blue">{{ displayPgsStsDcdNm(item.pgsStsDcd) }}</span>
									</div>
									<div class="textlist-item-header__top">
										<span class="textlist-item__date">{{ displaySpaceNm(item.spaceId) }}</span>
									</div>
									<div class="textlist-item-header__title">
										<div class="flex-1">
											<a href="javascript:" class="textlist-item__title" @click.prevent="showMore(idx)">{{ item.inqTit }}</a>
										</div>
										<div class="flex-0" v-if="item.pgsStsDcd !== '1412002'">
											<button type="button" class="button-default is-small is-rounded is-outlined" @click.prevent="onClickDelete(item.inqId)">삭제</button>
										</div>
									</div>
								</div>
								<transition name="slide">
									<div class="textlist-more" v-if="visibleMore === idx">
										<div class="textlist-item-content">
											<pre class="textlist-item-content__desc">{{ item.inqCn }}</pre>
											<span v-if="item.inqFileNm !== '' && item.inqFileNm !== null" class="textlist-item-content__attach"
												>[첨부파일] <a @click="onClickDownAttach(item.inqFileUrl)" target="_blank">{{ item.inqFileNm }}</a></span
											>
										</div>
										<div class="textlist-reply" v-if="item.pgsStsDcd === '1412002'">
											<strong class="textlist-reply__title">[답변]</strong>
											<pre v-html="item.ansCn"></pre>
											<div class="textlist-reply__date">{{ regDtTimeFormat(item.mdfcnDt) }}</div>
										</div>
									</div>
								</transition>
							</div>
						</li>
					</ul>
				</div>
				<div class="reserv-pagination-wrap" v-if="this.inquiryList.length > 0">
					<portal-pagination 
						page-name="MyRsvtInquiry" 
						:page-no="pageNo" 
						:page-size="pageSize" 
						:page-list-size="pageListSize"
						:total-count="totalCount" 
						:query="queries" 
						:parent-method-name="pagingMethodName" 
						@pagingChange="pagingChange"
				></portal-pagination>
				</div>
				<loading-modal v-if="isLoading"></loading-modal>		
			</div>
			<!-- //e러닝 목록 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { 
	ACT_GET_SPACE_INQ_LIST,
	ACT_GET_COMMON_CODE_LIST,
	ACT_GET_SPACE_LIST,
	ACT_DELETE_SPACE_INQ, 
} from "@/store/_act_consts";
import { 
	MUT_SHOW_ALERT, 
	MUT_SHOW_CONFIRM, 
	MUT_SET_RETURN_ROUTE 
} from '@/store/_mut_consts';
import { 
	getItems,
	setPaging,
	timestampToDateFormat,
	lengthCheck,
	getCheckItems,
	isSuccess
} from "@/assets/js/utils";
import LoadingModal from '../../../components/common/LoadingModal.vue';
import PortalPagination from '@/components/PortalPaginationRoute';
export default {
	name: 'MyRsvtInquiry',
	components: {
		PortalPagination, 
		LoadingModal
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'commonCode']),
	},
	watch: {},
	data: () => ({
		visibleMore: null,
		totalCount: 10,
		pageSize: 10,
		pageNo: 1,
		pageListSize: 10,
		pagingMethodName: 'pagingChange',
		queries: {},
		inquiryList: [],
		localMapList: [],
		pgsStsDcds: [],
		dcdOptions:[],
		isLoading: false
	}),
	created() {
		this.getSpaceList();
		this.getDcdOptions();
		this.getInquiryList();
	},
	mounted() {},
	methods: {
    getSpaceList() {
			const payload = {
				pageNo: 1,
				pageSize: 999,
			};
			this.$store.dispatch(`space/${ACT_GET_SPACE_LIST}`, payload)
			.then(res => {
				this.localMapList = getCheckItems(res);
			});
		},
		getDcdOptions() {
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: 1412 })
				.then((res) => {
					if (lengthCheck(res)) {
						this.dcdOptions = getItems(res).filter((dcd) => dcd.masterYn === 'N') || [];
					}
				})
				.catch(console.log);
		},
		async getInquiryList() {
			const payload = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
			};
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_INQ_LIST}`, payload)
				.then((res) => {
					if (this.inquiryList.length > 0) this.inquiryList.length = 0;
					this.inquiryList = this.inquiryList.concat(getItems(res));
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		deleteInq(inqId) {
			const payload = {
				inqId
			}
			this.$store.dispatch(`space/${ACT_DELETE_SPACE_INQ}`, payload)
			.then(res => {
				this.isLoading = false
				if(isSuccess(res)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `문의내역이 삭제되었습니다.`,
						yesfunc: this.getInquiryList,
						rightNow: true
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
				}							
			});
		},
		pagingChange(no) {
			this.pageNo = no;
      		this.getInquiryList();
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd');
		},
		showMore(idx) {
			if (this.visibleMore === idx) {
				this.visibleMore = null;
			} else {
				this.visibleMore = idx;
			}
		},
		onClickDownAttach(url) {
			if(url) {
				window.open(url)
			}
		},
		onClickDelete(inqId){
			if(this.isLoading) return;
      			this.isLoading = true;
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: '삭제하시겠습니까?',
				showCancelButton: true,
				yesfunc: () => {this.deleteInq(inqId)},
				nofunc: () => {this.isLoading = false;},
			});
		},
		displaySpaceNm(spaceId){
			const findSpace = this.localMapList.find((item) => item.spaceId === spaceId)
			if(findSpace) return findSpace.spaceNm
		},
		displayPgsStsDcdNm(dcd){
			const findFilter = this.dcdOptions.filter((item) => item.dcd === dcd)
			if(findFilter.length){
				return findFilter[0].dcdVal
			}
		},
	},
	destroyed() {},
};
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
	max-height: 231px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
