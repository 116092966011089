<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li>
					<li>판로방송 구독현황</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 판로방송 목록 -->
			<div class="mypage2-broadcast">
				<!-- [DEV] 데이터 없는 경우 공통 -->
				<div class="no-results-found" v-if="programList.length == 0">
					<p class="text">해당 내용이 없습니다.</p>
				</div>
				<ul class="list-item-container">
					<li class="list-item" v-for="(program, idx) in programList" :key="idx">
						<span class="list-item-image">
							<!-- 카테고리(프로그램코드) 별 이미지 관리기능이 없는 관계 상 지정된 이미지를 직접표시 -->
							<img src="~@/assets/img/mypage/broadcast1.png" alt="소상공인 성공 길라잡이 나침 판" v-if="program.PROGRAM_CD == '01'" />
							<img src="~@/assets/img/mypage/broadcast2.png" alt="판로 궁금증 해결사 판 소리" v-else-if="program.PROGRAM_CD == '02'" />
							<img src="~@/assets/img/mypage/broadcast3.png" alt="판로 노하우 대공개 성장 판" v-else-if="program.PROGRAM_CD == '03'" />
							<img src="~@/assets/img/mypage/broadcast4.png" alt="지원사업이 궁금해 메뉴 판" v-else-if="program.PROGRAM_CD == '04'" />
							<img src="~@/assets/img/mypage/broadcast5.png" alt="MD와 함께 라면 완 판" v-else-if="program.PROGRAM_CD == '05'" />
							<img src="~@/assets/img/mypage/broadcast0.png" alt="대한민국 판로 정보의 중심 판로TV" v-else />
						</span>
						<div class="list-item-content display-flex">
							<strong class="list-item-title">{{ program.PROGRAM_NM }}</strong>
							<div class="list-item-txts">
								<p class="list-item-txts__desc" v-if="program.PROGRAM_CD == '01'">예비창업자, 소상공인의 이커머스 진출을 위한 정보를 알려드립니다.</p>
								<p class="list-item-txts__desc" v-else-if="program.PROGRAM_CD == '02'">다양한 분야의 전문가와 함께 소상공인이 실제 겪는 애로, 궁금증을 해결해드립니다.</p>
								<p class="list-item-txts__desc" v-else-if="program.PROGRAM_CD == '03'">다양한 지원사업에 참여했던 소상공인의 성공 노하우를 공유해드립니다.</p>
								<p class="list-item-txts__desc" v-else-if="program.PROGRAM_CD == '04'">한국중소벤처기업유통원에서 운영하는 다양한 소상공인 온라인 판로지원사업을 알려드립니다.</p>
								<p class="list-item-txts__desc" v-else-if="program.PROGRAM_CD == '05'">유통MD가 직접 출연하여 제품을 모집하고, 입점 결정된 제품에 대한 리뷰 및 입점 노하우를 알려드립니다.</p>
							</div>
							<div class="buttons">
								<toggle-switch :propModel="smsList" :value="program.PROGRAM_CD" class="color-orange" label="방송 알림받기" @changefunc="smsListChg(program.PROGRAM_CD)" />
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- //판로방송 목록 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_INSERT_PROG_SMSUSER } from '@/store/_act_consts';
import { getItems } from '@/assets/js/utils';
export default {
	name: 'Broadcast',
	components: {
		ToggleSwitch,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {
		session: {
			handler(newVal, oldVal) {
				this.getUserSmsList();
			},
			immediate: true,
		},
	},
	data: () => ({
		toggle1: false,
		programCd: '',
		programList: [],
		smsList: [], //프로그램코드 별 사용자SMS발송여부 체크박스
	}),
	mounted() {
		this.getLiveList();
	},
	methods: {
		async getLiveList() {
			await this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					sortCd: '04',
					pageIndex: 1,
					pageSize: 8,
					brcTyp: 2,
					rpYn: 'Y',
				})
				.then((res) => {
					this.programList = res.data.PROGRAM_LIST;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		async getUserSmsList() {
			//사용자SMS발송여부 체크박스 초기화

			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined') {
				await this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: this.session.userId,
					})
					.then((res) => {
						this.smsList.splice(0);
						var items = getItems(res);
						for (var i = 0; i < items.length; i++) {
							this.smsList.push(items[i].programCd);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		smsListChg(val) {
			if (typeof this.session.userId == 'undefined') {
				alert('로그인이 필요합니다.');
				this.$router.push({ name: 'Login' });
				return false;
			}

			var deleteFlag = false;
			for (var i = 0; i < this.smsList.length; i++) {
				if (val == this.smsList[i]) {
					deleteFlag = true; //체크박스에서 입력된 데이터가 있으면 delete
				}
			}
			if (deleteFlag) {
				//삭제
				this.$store
					.dispatch(`broadcast/${ACT_DELETE_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
						this.$parent.getUserSmsCount();
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				//등록
				this.$store
					.dispatch(`broadcast/${ACT_INSERT_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
						this.$parent.getUserSmsCount();
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
	},
	destroyed() {},
};
</script>
