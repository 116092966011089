<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 판로방송 보기 -->
			<section id="myhome-broadcast" class="mypage2-hsection">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">판로방송 보기</h3>
					<div class="flex-r">
						<button type="button" class="boradcast-button-cal" @click="showBroadcastCalendarModal">
							<i class="icon-tv"></i>
						</button>
						<router-link to="/broadcast" class="link-more">더보기</router-link>
					</div>
				</div>
				<div class="mypage2-hsection-content">
					<!-- [DEV] 데이터 없는 경우 공통 -->
					<div class="no-results-found" v-if="liveList.length == 0">
						<p class="text">해당 내용이 없습니다.</p>
					</div>

					<swiper class="swiper" ref="swiperTv" :options="swiperOptionBroadcast">
						<swiper-slide v-for="(item, idx) in liveList" :key="idx">
							<broadcast-card :type="item.BRC_STS_CD" :watch="item.INQ_CNT" :vodLink="`/broadcast/${item.BRC_INFO_NO}/view`" :dateDay="item.BRC_SCHDG_BGNG_DT.slice(5, 10)" :dateDayWeek="dateDayWeek(item)" :dateTime="`${item.BRC_SCHDG_BGNG_DT.slice(11, 16)} ~ ${item.BRC_SCHDG_END_DT.slice(11, 16)}`" :img="`${item.BRC_RPRS_IMG_URL}`" :alt="item.BRC_TIT" :title="item.TITLE" :subDesc="item.BRC_TIT" @clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section>
			<broadcast-calendar-modal v-if="broadcastCalendar.show" />
			<broadcast-live-modal v-if="broadcastLive.show" :brcInfoNo="this.brcInfoNo" />
			<!-- //판로방송 보기 -->
			<!-- 공지사항 -->
			<section id="myhome-community" class="mypage2-hsection">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">공지사항</h3>
					<div class="flex-r">
						<router-link to="/notice" class="link-more">더보기</router-link>
					</div>
				</div>
				<div class="mypage2-hsection-content is-onlytext">
					<div class="no-results-found" v-if="notices.length == 0">
						<p class="text">해당 내용이 없습니다.</p>
					</div>
					<div v-else>
						<ul class="simple-notice-list">
							<li v-for="(item, idx) in notices" :key="idx">
								<!-- <a :href="'/notice/' + item.ntcId + '/view'" class="simple-notice-list__title">{{ item.ntcTit }}</a> -->
								<router-link :to="'/notice/' + item.ntcId + '/view'" class="simple-notice-list__title">{{ item.ntcTit }}</router-link>
								<span class="simple-notice-list__date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</span>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<!-- //공지사항 -->

			<!-- 내가 참여 중인 커뮤니티 -->
			<!-- <section id="myhome-community" class="mypage2-hsection">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">내가 참여 중인 커뮤니티</h3>
					<div class="flex-r">
						<router-link to="/scommunity" class="link-more">더보기</router-link>
					</div>
				</div>
				<div class="mypage2-hsection-content">
					<div class="no-results-found" v-if="commuList.length == 0">
						<p class="text">해당 내용이 없습니다.</p>
					</div>

					<swiper class="swiper" ref="swiperCommunity" :options="swiperOptionCommunity">
						<swiper-slide v-for="(item, idx) in commuList" :key="idx">
							<router-link :to="`/scommunity/${item.cmntId}`" class="scomm-card">
								<div class="scomm-card-item">
									<span class="scomm-card-thumb">
										<img :src="item.rprsImgPtUrl" :alt="item.cmntNm" />
									</span>
									<div class="scomm-card-item__text">
										<h4 class="scomm-card-title">{{ item.cmntNm }}</h4>
									</div>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section> -->
			<!-- //내가 참여 중인 커뮤니티 -->

			<!-- e러닝 둘러보기 -->
			<!-- <section id="myhome-elearn" class="mypage2-hsection">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">e러닝 둘러보기</h3>
					<div class="flex-r">
						<router-link to="../education" class="link-more">더보기</router-link>
					</div>
				</div>
				<div class="mypage2-hsection-content">
					<div class="no-results-found" v-if="this.$parent.lmsList.length == 0">
						<p class="text">해당 내용이 없습니다.</p>
					</div>
					<swiper class="swiper" ref="swiperElearn" :options="swiperOptionElearn">
						<swiper-slide v-for="(item, idx) in this.$parent.lmsList" :key="idx">
							<router-link to="" class="scomm-card">
								<div class="scomm-card-item" @click="moveLms(item.lrnNo)">
									<span class="scomm-card-thumb">
										<img :src="item.courseImg" :alt="item.courseNm" />
									</span>
									<div class="scomm-card-item__text">
										<h4 class="scomm-card-title">{{ item.courseNm }}</h4>
									</div>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section> -->
			<!-- //e러닝 둘러보기 -->
			<!-- 소상공인 콘텐츠 -->
			<!-- <section id="myhome-contents" class="mypage2-hsection is-fullwidth">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">소상공인 콘텐츠</h3>
				</div>
				<div class="button-tabs">
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tmpCategory == 1 }" @click="tmpCategory = 1">소상공인 포스트</button>
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tmpCategory == 2 }" @click="tmpCategory = 2">소상공인 인터뷰</button>
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tmpCategory == 3 }" @click="tmpCategory = 3">디지털 전환지원</button>
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tmpCategory == 4 }" @click="tmpCategory = 4">소상공인 웹예능</button>
				</div>
				<div class="mypage2-hsection-content" v-if="tmpCategory == 1">
					<swiper class="swiper" ref="swiperContents" :options="swiperOptionContents">
						<swiper-slide v-for="(item, idx) in this.tmp1List" :key="idx">
							<router-link :to="`/issue/${item.issueId}/view`" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">{{ item.issueSmlTit }}</span>
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">{{ item.issueTit }}</strong>
									<span class="fp-digital-card__body-img">
										<img :src="item.rprsImgPtUrl" alt="" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
				<div class="mypage2-hsection-content" v-else-if="tmpCategory == 2">
					<swiper class="swiper" ref="swiperContents" :options="swiperOptionContents">
						<swiper-slide v-for="(item, idx) in this.tmp2List" :key="idx">
							<router-link :to="`/interview/${item.interviewId}/view`" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">{{ item.interviewSmlTit }}</span>
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">{{ item.interviewTit }}</strong>
									<span class="fp-digital-card__body-img">
										<img :src="item.rprsImgPtUrl" alt="" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
				<div class="mypage2-hsection-content" v-else-if="tmpCategory == 3">
					<swiper class="swiper" ref="swiperContents" :options="swiperOptionContents">
						<swiper-slide v-for="(item, idx) in this.tmp3List" :key="idx">
							<router-link :to="`/community/${item.comunBltartclId}/view?ctgrCode=${item.comunCtgrDcd}`" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">{{ item.comunCtgr }}</span>
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">{{ item.comunBltartclTit }}</strong>
									<span class="fp-digital-card__body-img">
										<img :src="item.thmbnlImgPtUrl" alt="" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
				<div class="mypage2-hsection-content" v-else-if="tmpCategory == 4">
					<swiper class="swiper" ref="swiperContents" :options="swiperOptionContents">
						<swiper-slide v-for="(item, idx) in this.tmp4List" :key="idx">
							<router-link :to="`/playground/${item.plygrndId}/view?ctgrCode=${item.plygrndCtgrDcd}`" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">{{ item.plygrndCtgr }}</span>
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">{{ item.plygrndTit }}</strong>
									<span class="fp-digital-card__body-img">
										<img :src="item.rprsImgPtUrl" alt="" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section> -->
			<!-- //소상공인 콘텐츠 -->

			<!-- 소상공인 뉴스 -->
			<section id="myhome-contents" class="mypage2-hsection is-fullwidth">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">소상공인 뉴스</h3>
					<!-- 신규기능 > 소상공인콘텐츠가 완성된 후 링크연결예정
					<div class="flex-r">
						<router-link to="" class="link-more">더보기</router-link>
					</div>
					-->
				</div>
				<div class="button-tabs">
					<button v-for="(item, idx) in sentcontCtgrItems" :key="idx" type="button" class="button-default is-rounded" :class="{ 'is-active': sentContCtgrDcd === item.dcd }" @click="sentContCtgrDcd = item.dcd">{{ item.dcdVal }}</button>					
				</div>
				<div class="mypage2-hsection-content">
					<div class="no-results-found" v-if="sentcontItems.length === 0">
						<p class="text">소상공인 뉴스가 존재하지 않습니다.</p>
					</div>
					<swiper class="swiper" v-else ref="swiperContents" :options="swiperOptionContents">
						<swiper-slide v-for="(item, idx) in sentcontItems.find((x) => x.sentContCtgrDcd === sentContCtgrDcd) ? sentcontItems.find((x) => x.sentContCtgrDcd === sentContCtgrDcd).list : []" :key="idx">
							<router-link :to="`/sentcont/${item.sentContCtgrDcd}/view/${item.sentContId}`" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">{{ item.sentContSmlTit }}</span>
									<!-- <em class="card-tag">NEW</em> -->
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">{{ item.sentContTit }}</strong>
									<span class="fp-digital-card__body-img">
										<img :src="item.thmbnlImgPtUrl" :alt="item.sentContTit" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</section>
			<!-- //소상공인 뉴스 -->

			<!-- s: 지원사업 -->
			<section class="mypage2-hsection is-fullwidth">
				<div class="mypage2-hsection-header">
					<h3 class="mypage2-hsection-header__title">지원사업</h3>
				</div>
				<div class="button-tabs">
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tabBizList == 1 }" @click="tabBizList = 1">중소벤처기업부</button>
					<button type="button" class="button-default is-rounded" :class="{ 'is-active': tabBizList == 2 }" @click="getSuptBiz()">지방자치단체</button>
				</div>
				<!-- s: 중소벤처기업부 -->
				<div class="mypage2-hsection-content" id="myhome-biz1" v-if="tabBizList === 1">
					<div class="no-results-found" v-if="this.mssSprtBizList.length == 0">
						<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
					</div>
					<swiper class="swiper" v-else ref="swiperBiz" :options="swiperOptionBiz1">
						<swiper-slide v-for="(mssSprtBiz, idx) in mssSprtBizList" :key="idx">
							<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank" class="sptpgm-article">
								<header class="sptpgm-article-header">
									<span class="sptpgm-article-header__tag">{{ mssSprtBiz.sprtBizTyNm }}</span>
									<span class="sptpgm-article-header__tag">{{ mssSprtBiz.sprtBizCtpvNm }}</span>
									<span class="sptpgm-article-header__dday">D-{{ mssSprtBiz.dday }}</span>
								</header>
								<div class="sptpgm-article-body">
									<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank">
										<h3 class="sptpgm-article-title">{{ mssSprtBiz.sprtBizNm }}</h3>
										<p class="sptpgm-article-desc">{{ mssSprtBiz.sprtBizPurpose }}</p>
									</a>
								</div>
								<footer class="sptpgm-article-footer">
									<dl class="sptpgm-article-dl">
										<dt><i class="icon-fp-date"></i><span class="sr-only">기간</span></dt>
										<dd>{{ mssSprtBiz.rcritBgngYmd }} ~ {{ mssSprtBiz.rcritEndYmd }}</dd>
									</dl>
									<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
								</footer>
							</a>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
					<!-- <div class="fp-tabpanel-buttons">
						<router-link to="/supportbiz?showBizAside=true" class="button-default is-rounded">더보기</router-link>
					</div> -->
				</div>
				<!-- e: 중소벤처기업부 -->

				<!-- s: 지자체 -->
				<div class="mypage2-hsection-content" id="myhome-biz2" v-else-if="tabBizList === 2">
					<div class="no-results-found" v-if="this.bizList2.length == 0">
						<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
					</div>
					<swiper class="swiper" ref="swiperBiz" :options="swiperOptionBiz2">
						<swiper-slide v-for="(item, idx) in this.bizList2" :key="idx">
							<a :href="`https://www.bizinfo.go.kr${item.pblancUrl}`" class="sptpgm-article" target="_blank">
								<header class="sptpgm-article-header">
									<span class="sptpgm-article-header__tag">{{ item.pldirSportRealmLclasCodeNm }}</span>
									<span class="sptpgm-article-header__tag" v-text="getAreaInfo(item.pblancNm)"></span>
									<span class="sptpgm-article-header__dday" v-text="getDay(item.reqstEndDe) <= 0 ? (getDay(item.reqstEndDe) === 0 ? 'D-Day' : `D+${getDay(item.reqstEndDe) * -1}`) : `D-${getDay(item.reqstEndDe)}`"></span>
								</header>
								<div class="sptpgm-article-body">
									<h3 class="sptpgm-article-title">
										<a :href="`https://www.bizinfo.go.kr${item.pblancUrl}`" target="_blank">{{ item.pblancNm }}</a>
									</h3>
									<p class="sptpgm-article-desc" v-html="item.bsnsSumryCn"></p>
								</div>

								<footer class="sptpgm-article-footer">
									<dl class="sptpgm-article-dl">
										<dt><i class="icon-fp-date"></i><span class="sr-only">기간</span></dt>
										<dd>{{ getBiz2Date(item) }}</dd>
									</dl>
									<a :href="`https://www.bizinfo.go.kr${item.pblancUrl}`" target="_blank" class="button-default is-primary is-rounded">상세보기</a>
								</footer>
							</a>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
					<!-- <div class="fp-tabpanel-buttons">
						<router-link to="/supportbiz?showBizAside=false" class="button-default is-rounded">더보기</router-link>
					</div> -->
				</div>
				<!-- e: 지자체 -->
			</section>
			<!-- e: 지원사업 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_MSS_SPRT_BIZ_LIST, ACT_GET_BIZ_LIST2, ACT_GET_INTERVIEW_LIST, ACT_GET_ISSUE_LIST, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_MY_SCOMMUNITY_LIST, ACT_GET_PLAYGROUND_LIST, ACT_GET_SB_COMMUNITY_LIST, ACT_POST_EDU_SSO, ACT_GET_NOTICE2_LIST, ACT_CHECK_SESSION, ACT_GET_COMMON_CODE_LIST, ACT_GET_SENTCONT_LIST } from '../../../store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_BROADCAST_CALENDAR_MODAL, MUT_SHOW_BROADCAST_LIVE_MODAL, MUT_SHOW_BROADCAST_VOD_MODAL, MUT_SET_RETURN_BRC_INFO_NO } from '../../../store/_mut_consts';
import { getItem, getItems, getCheckItems, isSuccess, lengthCheck, setPaging, tryResCallback, timestampToDateFormat, ymdToFormat } from '@/assets/js/utils';
import BroadcastCard from '../../../components/broadcast/BroadcastCard';
import BroadcastLiveModal from '../../../components/broadcast/BroadcastLiveModal';
import BroadcastCalendarModal from '../../../components/broadcast/BroadcastCalendarModal';

export default {
	name: 'Home',
	components: {
		BroadcastCard,
		BroadcastCalendarModal,
		BroadcastLiveModal,
	},
	async created() {
		//await this.$store.dispatch(`auth/${ACT_CHECK_SESSION}`);
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		swiperOptionBroadcast() {
			return {
				...this.swiperOption2,
				...this.swiperOptionBroadcastNavi,
			};
		},
		swiperOptionCommunity() {
			return {
				...this.swiperOption2,
				...this.swiperOptionCommunityNavi,
			};
		},
		swiperOptionBiz1() {
			return {
				...this.swiperOption4,
				...this.swiperOptionBiz1Navi,
			};
		},
		swiperOptionBiz2() {
			return {
				...this.swiperOption4,
				...this.swiperOptionBiz2Navi,
			};
		},
		swiperOptionElearn() {
			return {
				...this.swiperOption2,
				...this.swiperOptionElearnNavi,
			};
		},
		swiperOptionContents() {
			return {
				...this.swiperOption4,
				...this.swiperOptionContentsNavi,
			};
		},
	},
	watch: {
		session: {
			handler(newVal, oldVal) {
				//this.getMyCommyList();  //기능삭제로 미실행처리
			},
			immediate: true,
		},
		tmpCategory: {
			handler() {
				switch (this.tmpCategory) {
					case 1:
						this.getTmp1List();
						break;
					case 2:
						this.getTmp2List();
						break;
					case 3:
						this.getTmp3List();
						break;
					case 4:
						this.getTmp4List();
						break;
				}
			},
			immediate: true,
		},
	},
	data: () => ({
		notices: [], //공지사항 목록
		programCd: '',
		liveList: [],
		commuList: [],
		mssSprtBizList: [], //중소벤처기업부 지원사업 목록
		bizList2: [],
		tabBizList: 1,

		tmpCategory: 1,
		tmp1List: [], //소상공인 포스트(임시)
		tmp2List: [], //소상공인 인터뷰(임시)
		tmp3List: [], //디지털 전환지원(임시)
		tmp4List: [], //소상공인 웹예능(임시)

		swiperOption1: {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,
			breakpoints: {
				200: {
					spaceBetween: 5,
					slidesPerView: 1.2,
				},
				430: {
					spaceBetween: 5,
					slidesPerView: 2.2,
				},
				600: {
					spaceBetween: 5,
					slidesPerView: 3.2,
				},
				801: {
					spaceBetween: 30,
					slidesPerView: 1,
				},
			},
		},
		swiperOption2: {
			loop: false,
			slidesPerView: 2,
			spaceBetween: 30,

			breakpoints: {
				200: {
					spaceBetween: 5,
					slidesPerView: 1,
				},
				340: {
					spaceBetween: 5,
					slidesPerView: 2,
				},
				550: {
					spaceBetween: 5,
					slidesPerView: 3,
				},
				801: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
				1070: {
					spaceBetween: 30,
					slidesPerView: 4,
				},
				1328: {
					spaceBetween: 30,
					slidesPerView: 2,
				},
			},
		},
		swiperOption4: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 30,
			breakpoints: {
				200: {
					spaceBetween: 5,
					slidesPerView: 1,
				},
				340: {
					spaceBetween: 5,
					slidesPerView: 2,
				},
				550: {
					spaceBetween: 5,
					slidesPerView: 3,
				},
				801: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
				1070: {
					spaceBetween: 30,
					slidesPerView: 4,
				},
				1328: {
					spaceBetween: 30,
					slidesPerView: 4,
				},
			},
		},
		swiperOptionBroadcastNavi: {
			navigation: {
				nextEl: '#myhome-broadcast .swiper-button-next',
				prevEl: '#myhome-broadcast .swiper-button-prev',
			},
		},

		swiperOptionCommunityNavi: {
			navigation: {
				nextEl: '#myhome-community .swiper-button-next',
				prevEl: '#myhome-community .swiper-button-prev',
			},
		},
		swiperOptionElearnNavi: {
			navigation: {
				nextEl: '#myhome-elearn .swiper-button-next',
				prevEl: '#myhome-elearn .swiper-button-prev',
			},
		},
		swiperOptionBiz1Navi: {
			navigation: {
				nextEl: '#myhome-biz1 .swiper-button-next',
				prevEl: '#myhome-biz1 .swiper-button-prev',
			},
		},
		swiperOptionBiz2Navi: {
			navigation: {
				nextEl: '#myhome-biz2 .swiper-button-next',
				prevEl: '#myhome-biz2 .swiper-button-prev',
			},
		},
		swiperOptionContentsNavi: {
			navigation: {
				nextEl: '#myhome-contents .swiper-button-next',
				prevEl: '#myhome-contents .swiper-button-prev',
			},
		},
		sentContCtgrDcd: 1,
		sentcontCtgrItems: [],
		sentcontItems: [],
	}),
	mounted() {
		this.getLiveList();
		this.getMssSprtBizList();
		this.getBizList2();
		this.getNotices();
		this.getSentContCtgrList();
	},
	methods: {
		timestampToDateFormat,
		getNotices() {
			this.$store
				.dispatch(`notice2/${ACT_GET_NOTICE2_LIST}`, {
					pageSize: 7,
				})
				.then((res) => {
					this.notices = this.notices.concat(getItems(res));
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getBiz2Date: function (item) {
			var res = '';
			res += item.reqstBeginDe.substr(0, 4) + '-';
			res += item.reqstBeginDe.substr(4, 2) + '-';
			res += item.reqstBeginDe.substr(6, 2);
			res += ' ~ ';
			res += item.reqstEndDe.substr(0, 4) + '-';
			res += item.reqstEndDe.substr(4, 2) + '-';
			res += item.reqstEndDe.substr(6, 2);

			return res;
		},
		dateDayWeek: function (item) {
			const week = ['일', '월', '화', '수', '목', '금', '토'];
			var date = new Date(item.BRC_SCHDG_BGNG_DT);
			return week[date.getDay()];
		},
		showBroadcastCalendarModal() {
			this.$store.commit(`broadcast/${MUT_SHOW_BROADCAST_CALENDAR_MODAL}`, {});
		},
		showBroadcastLiveModal(brcInfoNo) {
			//라이브방송 비디오모달창을 띄워서 재생하는 방식
			//this.brcInfoNo = brcInfoNo;
			//this.$store.commit(`broadcast/${MUT_SHOW_BROADCAST_LIVE_MODAL}`, {});

			//판로TV페이지로 이동하여 재생하는 방식 > 비디오모달에서 로그인 시 화면이동문제 및 유지보수 문제로 이방식을 선택
			this.$store.commit(`broadcast/${MUT_SET_RETURN_BRC_INFO_NO}`, brcInfoNo);
			this.$router.push({ name: 'BroadCast', params: { brcInfoNo: brcInfoNo } }).catch(() => {});
		},
		movePage(target) {
			this.$router.push({ name: target });
		},
		async getLiveList() {
			await this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					sortCd: '04',
					pageIndex: 1,
					pageSize: 8,
					brcTyp: 2,
					rpYn: 'Y',
				})
				.then((res) => {
					//console.log('getLiveList res : ', res);
					this.liveList = res.data.LIST;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		async getMyCommyList() {
			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				await this.$store
					.dispatch(`mypage2/${ACT_GET_MY_SCOMMUNITY_LIST}`, {
						userId: this.session.userId,
						params: {
							pageNo: 1,
							pageSize: 99,
						},
					})
					.then((res) => {
						this.commuList = getItems(res);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				this.commuList.splice(0);
			}
		},
		getMssSprtBizList() {
			this.$nextTick(() => {
				// api 호출
				this.$store
					.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_LIST}`, {
						pageNo: 1,
						pageSize: 5,
					})
					.then((res) => {
						this.mssSprtBizList = getCheckItems(res);
					})
					.catch((e) => {
						console.error(e);
					});
			});
		},
		getAreaInfo(pblancNm) {
			const areaList = ['충북', '충남', '제주', '전북', '전남', '인천', '울산', '세종', '서울', '부산', '대전', '대구', '기타', '광주', '경북', '경남', '경기', '강원'];
			let area = pblancNm.substr(1, 2);
			if (!areaList.includes(area)) area = '전국';
			return area;
		},
		getDay(reqstEndDe) {
			const reqEndDate = ymdToFormat(reqstEndDe, '-');
			const endDate = new Date(reqEndDate);
			const today = new Date();
			const diffDate = endDate - today;
			return Math.floor(diffDate / (1000 * 3600 * 24)) + 1;
		},
		getSuptBiz() {
			// 지자체 지원 사업 탭 클릭 시 해당 func 호출
			// 기존 showBiz(true) 처리를 여기 처리
			this.tabBizList = 2;
			// api 호출
			this.getBizList2();
		},
		async getBizList2() {
			await this.$store
				.dispatch(`mypage2/${ACT_GET_BIZ_LIST2}`, {
					pageNo: 1,
					pageSize: 5,
				})
				.then((res) => {
					this.bizList2 = getItems(res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		goSCommunity(cmntId) {
			this.$router.push(`/scommunity/${cmntId}`);
		},
		async getTmp1List() {
			await this.$store
				.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
					pageNo: 1,
					pageSize: 8,
					sortCd: '',
				})
				.then((res) => {
					//console.log('getIssueList res : ', res);
					this.tmp1List = res.items;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		async getTmp2List() {
			await this.$store
				.dispatch(`information/${ACT_GET_INTERVIEW_LIST}`, {
					pageNo: 1,
					pageSize: 8,
					sortCd: '',
				})
				.then((res) => {
					//console.log('getInterviewList res : ', res);
					this.tmp2List = res.items;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		async getTmp3List() {
			await this.$store
				.dispatch(`community/${ACT_GET_SB_COMMUNITY_LIST}`, {
					comunCtgrDcd: '',
					pageNo: 1,
					pageSize: 8,
					sortCd: '',
				})
				.then((res) => {
					//console.log('getSbCommunityList res : ', res);
					this.tmp3List = res.items;
				})
				.catch((e) => {
					console.error(e);
					this.items = [];
					this.isLoading = false;
				});
		},
		async getTmp4List() {
			await this.$store
				.dispatch(`information/${ACT_GET_PLAYGROUND_LIST}`, {
					plygrndTag: '',
					plygrndCtgrDcd: '',
					pageNo: 1,
					pageSize: 8,
					sortCd: '',
				})
				.then((res) => {
					//console.log('getPlaygroundList res : ', res);
					this.tmp4List = res.items;
				})
				.catch((e) => {
					console.error(e);
					this.isLoading = false;
				});
		},
		moveLms(lrnNo) {
			this.$store.dispatch(`education/${ACT_POST_EDU_SSO}`).then((res) => {
				if (isSuccess(res)) {
					if (res.encId) {
						location.href = `https://kodma.getsmart.co.kr/client/sso?memberId=${encodeURIComponent(res.encId)}&page=${lrnNo}`;
					}
				}
			});
		},
		getSentContCtgrList() {
			//소상공인컨텐츠 카테고리 리스트 불러오기
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
					dcd: '1202',
					masterYn: 'N',
				})
				.then((res) => {
					this.sentcontCtgrItems = getCheckItems(res);
					//this.$store.commit(`sentcont/${MUT_SET_SENT_CONT_CTGR_ITEMS}`, items);
					if(this.sentcontCtgrItems.length > 0){
						this.sentContCtgrDcd = this.sentcontCtgrItems[0].dcd;
						this.sentcontCtgrItems.forEach((element) => {
							this.getSentContList(element);
						});
					}
				});
		},
		getSentContList(item) {
			//소상공인컨텐츠 리스트 불러오기
			this.$store
				.dispatch(`sentcont/${ACT_GET_SENTCONT_LIST}`, {
					sentContCtgrDcd: item.dcd,
					expsrYn: 'Y',
					pageNo: 1,
					pageSize: 8,
				})
				.then((res) => {
					const list = getCheckItems(res);
					if (list.length > 0) {
						//item.list = list.slice(0, this.ctgrItemsListSize);
						this.sentcontItems.push({ sentContCtgrDcd: item.dcd, sentContCtgrNm: item.dcdVal, list: list });
					} else {
						//item.list = [];
						this.sentcontItems.push({ sentContCtgrDcd: item.dcd, sentContCtgrNm: item.dcdVal, list: [] });
					}
				});
		},
	},
	destroyed() {},
};
</script>
